import { Row, Col } from 'antd'

import { Details, HtmlText, ImageGallery, VideoGallery } from 'common/components'
import { ClientValue } from 'components/Client'
import { CityValue } from 'components/City'
import { DistrictValue } from 'components/District'
import { useFeatureTypeList } from 'store/api/featureTypeApi'

/*
"advertType"
"categories"
"status", "statusComment", "changedFields",
"createdAt", "changedAt",

"isVerified", "isMark", "isVip", "activeUntil",

"raiseAuto", "raisePeriod", "raiseCheckDate", "markAuto", "markCheckDate", "vipAuto", "vipCheckDate",

---

"site", 
"isActive"
*/


const AdvertDetail = ({ detail }) => {
  const photos = detail?.photos || [];
  const videos = detail?.videos || [];

  const hasMedia = photos.length > 0 || videos.length > 0;

  const adertType = detail?.advertType;

  const [featureTypes] = useFeatureTypeList({ adertType }, !adertType ? [] : null);
  const features = detail?.features || {};

  return (
    <Row gutter={16} wrap={false}>
      {hasMedia &&
        <Col flex="0 0 300px">
          <Details labelWidth={150}>
            <Details.Item>
              <ImageGallery items={detail?.photos} />
            </Details.Item>
          </Details>

          {videos.length > 0 &&
            <Details labelWidth={150}>
              <Details.Item>
                <VideoGallery items={detail?.videos} />
              </Details.Item>
            </Details>}
        </Col>}

      <Col flex="1 1 auto">
        <Details itemsType="block">
          <Details.Item label="Название">{detail?.name}</Details.Item>
        </Details>

        {featureTypes.length > 0 &&
          <Details title="Свойства" itemsType="block">
            {featureTypes.map(feature => {
              const value = features[feature.code];
              return (
                <Details.Item key={feature.code} label={feature.name} span={12}>{value}</Details.Item>
              )
            })}
          </Details>}

        <Details itemsType="block">
          <Details.Item label="Клиент"><ClientValue detail={detail?.user} /></Details.Item>
          <Details.Item label="Категории">

          </Details.Item>
        </Details>

        <Details title="Контакты" itemsType="block">
          <Details.Item label="Город" span={12}><CityValue detail={detail?.city} /></Details.Item>
          <Details.Item label="Район" span={12}><DistrictValue detail={detail?.district} /></Details.Item>

          <Details.Item label="Телефон" span={8}>{detail?.contactPhone}</Details.Item>
          <Details.Item label="Whatsapp" span={8}>{detail?.contactWhatsapp}</Details.Item>
          <Details.Item label="Telegram" span={8}>{detail?.contactTelegram}</Details.Item>
        </Details>

        <Details title="Описание">
          <Details.Item><HtmlText text={detail?.text} minimize={false} /></Details.Item>
        </Details>
      </Col>
    </Row>
  )
}

export default AdvertDetail
