import classNames from 'classnames'
import ReactImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"

import { getAbsoluteUrl, getThumbnailUrl } from 'common/utils/helpers'

import './styles.css'


const ImageGallery = ({ className, style, items }) => {
  const _items = (items || []).map(item => ({
    original: getThumbnailUrl(item.pk, 800, 800),
    thumbnail: getThumbnailUrl(item.pk, 50, 50),
    fullscreen: getAbsoluteUrl(item.url),
  }));

  const cx = classNames(["imageGallery", className]);

  return (
    <div className={cx} style={style}>
      {_items.length > 0 && <ReactImageGallery items={_items} showPlayButton={false} slideOnThumbnailOver={true} />}
    </div>
  )
}

export default ImageGallery;
