import { useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { Skeleton } from 'antd'

import { Page } from 'common/layout'


const DetailPage = ({
  children,
  detailPK,
  detail,

  apiGetDetail,
  apiGetDetailProps,
  apiDelete,
  apiDeleteProps,

  basePath,
  title,
  showBack,
  tabs,
  onTabChange,

  actions,
  otherActions,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    apiGetDetail(detailPK);
  }, [detailPK, apiGetDetail]);

  useEffect(() => {
    if (apiDeleteProps?.isSuccess) {
      navigate(basePath)
    }
  }, [apiDeleteProps?.isSuccess, basePath, navigate]);

  const onDelete = () => {
    apiDelete && apiDelete(detailPK)
  }

  const _otherActions = otherActions || [{ icon: 'delete', label: 'Удалить', danger: true, onClick: () => onDelete() }];

  return (
    <Page
      title={title}
      showBack={showBack}
      actions={detail ? actions: null}
      otherActions={detail ? _otherActions: null}
      basePath={basePath}
      tabs={tabs}
      onTabChange={onTabChange}
    >
      {detail ? children : <Skeleton active={apiGetDetailProps.isLoading} />}
    </Page>
  );
}

export default DetailPage
