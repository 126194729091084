import { useState } from 'react'
import classNames from 'classnames'

import './styles.css'


export default function HtmlText({ className, text, minimize = false }) {
  const [isShowFull, setIsShowFull] = useState(false);

  if (!text) return null;

  const isTextBig = minimize && text.length > 314;

  const cx = classNames({
    'htmlText': true,
    'htmlText_big': isTextBig && !isShowFull,
    [className]: true,
  });

  return (
    <div className={cx}>
      <div className="htmlText__text" dangerouslySetInnerHTML={{ __html: text }} />

      {isTextBig && !isShowFull && <div className="htmlText__more" onClick={() => setIsShowFull(true)}>Подробнее</div>}
      {isTextBig && isShowFull && <div className="htmlText__more" onClick={() => setIsShowFull(false)}>Свернуть</div>}
    </div>
  );
}
