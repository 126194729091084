import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { DetailPage } from 'common/layout'
import { OnOff } from 'common/components'

import AdvertApi, { pkField } from 'store/api/advertApi'
import { AdvertActions } from 'store/features/advertSlice'
import { AdvertDetail, AdvertDetailPrices, AdvertForm } from 'components/Advert'


const AdvertDetailPage = () => {
  const { sitePK, advertTypePK, advertPK } = useParams();
  const detailPK = advertPK;

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = AdvertApi.endpoints.getAdvertDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = AdvertApi.endpoints.updateAdvert.useMutation();
  const [apiDelete, apiDeleteProps] = AdvertApi.endpoints.deleteAdvert.useMutation();

  const detail = apiGetDetailProps.data;

  const onEdit = () => {
    dispatch(AdvertActions.openForm({ name: 'editForm', pk: detailPK }))
  };

  const activeSwitch = (
    <OnOff
      name="Advert"
      detail={detail}
      pkField={pkField}
      fieldName="isEnabled"
      apiUpdate={apiUpdate}
      apiUpdateProps={apiUpdateProps}
    />
  );

  const detailTitle = detail?.name ? `«${detail?.name}»` : '';


  const [view, setView] = useState('main');


  return (
    <DetailPage
      title={`Объявление ${detailTitle}`}
      detailPK={detailPK}
      detail={detail}

      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      actions={[
        { label: 'Включено', component: activeSwitch },
        { icon: 'edit', label: 'Редактировать', type: 'primary', onClick: () => onEdit() },
      ]}

      basePath={`/sites/${sitePK}/adverts/${advertTypePK}/detail/${detailPK}/`}
      tabs={[
        { label: 'Основное', key: 'main' },
        { label: 'Услуги и цены', key: 'prices' },
      ]}
      onTabChange={key => setView(key)}
    >
      {view === 'main' && <AdvertDetail detail={detail} />}
      {view === 'prices' && <AdvertDetailPrices detail={detail} />}

      <AdvertForm site={sitePK} advertType={advertTypePK} showGoToDetailButton={false} />
    </DetailPage>
  );
}

export default AdvertDetailPage
