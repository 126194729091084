import classNames from 'classnames'
import { Player } from 'video-react'
import 'video-react/dist/video-react.css'

import { getAbsoluteUrl } from 'common/utils/helpers'

import './styles.css'


const VideoGallery = ({ className, style, items }) => {
  const _items = items || [];

  const cx = classNames(["videoGallery", className]);

  return (
    <div className={cx} style={style}>
      {_items.map(video => <Player key={video.url} src={getAbsoluteUrl(video.url)} />)}
    </div>
  )
}

export default VideoGallery;
