import { useMemo } from 'react'
import { Row, Col, Tree } from 'antd'

import { Details } from 'common/components'
import { usePriceTypeList } from 'store/api/priceTypeApi'
import { useServiceList } from 'store/api/serviceApi'


const getServiceTree = (serviceList, services) => {
  return serviceList.reduce((res, item) => {
    if (item.children?.length) {
      const children = getServiceTree(item.children, services);
      if (children.length) {
        res.push({ ...item, key: item.code, children });
      }
    } else if (item.code in services) {
      res.push({ ...item, key: item.code, price: services[item.code] });
    }
    return res;
  }, [])
}


const AdvertDetailPrices = ({ detail }) => {
  const adertType = detail?.advertType;

  const [priceTypes] = usePriceTypeList({ adertType }, !adertType ? [] : null);
  const prices = detail?.prices || {};

  const services = detail?.services;
  const serviceCodes = Object.keys(services || {});
  const [serviceList] = useServiceList({ adertType, code__in: serviceCodes }, (!adertType || serviceCodes.length === 0) ? [] : null);

  const serviceTree = useMemo(() => {
    return getServiceTree(serviceList, services || {});
  }, [serviceList, services]);


  return (
    <Row gutter={16} wrap={false}>
      <Col flex="1 1 auto">
        {priceTypes.length > 0 &&
          <Details title="Цены" itemsType="block">
            {priceTypes.map(item => {
              const value = prices[item.code];
              return (
                <Details.Item key={item.code} label={item.name}>{value}</Details.Item>
              )
            })}
          </Details>}

        {serviceTree.length > 0 &&
          <Details title="Услуги">
            <Tree
              selectable={false}
              autoExpandParent={true}
              defaultExpandAll={true}
              treeData={serviceTree}
              titleRender={service => {
                if (service.children?.length) return service.name;
                return (
                  <Row gutter={16} align="middle">
                    <Col span={8}>
                      <nobr>{service.name}{service.price ? ': ' : ''}</nobr>
                      <nobr>{service.price}</nobr>
                    </Col>
                  </Row>
                )
              }}
            />
          </Details>}
      </Col>
    </Row>
  )
}

export default AdvertDetailPrices
